import * as React from "react";
import { StyledPreview } from "./Preview.style";
import IconButton from "../../elements/IconButton";
import { useDispatch } from "react-redux";
import { closePreview } from "../../../state/cart/actions";

interface Props{
    src: string;
}

const Preview = (props:Props) =>
{
    const dispatch = useDispatch();

    return <StyledPreview>
        <IconButton className="close" icon="close" onClick={() => dispatch(closePreview())}/>
        <div>
            <img alt="preview" src={props.src} />
        </div>
    </StyledPreview>;
}

export default Preview;