import * as React from "react";
import Panel from "./Panel";
import Slider from "components/elements/Slider";
import Toggle from "components/elements/Toggle";
import Radio from "components/elements/Radio";
import Button from "components/elements/Button";
import Stepper from "components/elements/Stepper";
import Content from "./Content";
import Tabs from "./Tabs";
import Search from "../Controls/Search";
import IconButton from "components/elements/IconButton";
import {HideContent, StyledSidebar, StyledSidebarNav} from "./Sidebar.style";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "state/state";
import {Cart, PAGES} from "typings/cart";
import {
    changeModuleHideNotification,
    changePage,
    changePageHideNotification,
    closeSidebarPreview,
    setCamera,
    showPreview,
    showSidebarPreview,
    updateCartAccessory,
    updateCartAdditional,
    updateSidebarPreview,
} from "state/cart/actions";
import {SidebarPreviewContainer, SidebarPreviewHeader} from "./Product.style";
import {getStep3, getStep4} from "services/repo";
import {getImage, getProductLine, isMobile} from "utils/utils";
import Details from "./Details";
import {Product, RepoState} from "typings/repo";
import {getProduct, Material, searchProduct} from "state/repo";
import {selectModule, setAllParts,} from "state/configuration/actions";
import {getItemPrice} from "helpers/priceHelper";
import {
    allowAllTableTops,
    changeModuleSize,
    getConfigurationProducts,
    getCustomSize,
    getSize,
} from "helpers/configurationHelper";
import {TrackEvent} from "helpers/Tracking";
import {sendMail} from "components/billing/CheckoutMail";
import {api} from "api/Api";
import SidebarCart from "./components/SidebarCart";
import CartPage from "./components/CartPage";
import Warning from "./components/Warning";
import {clearCartUser} from "state/user/actions";
import {Module} from "typings/configuration";

interface Props {
    setMobile?: (e: boolean) => void;
    toggle?: boolean;
}

const Sidebar = (props: Props) => {
    const state = useSelector((state: RootState) => state);
    const configuration = state.configuration;
    const tokens = state.repo.translations;
    const [panel, updatePanel] = React.useState(0);
    const total = 4;
    const [accessoriesSearch, setAccessoriesSearch] = React.useState("");
    const [additionalSearch, setAdditionalSearch] = React.useState("");
    const [activeTab, updateTab] = React.useState(0);

    const [activeMenuTab, updateMenuTab] = React.useState(0);

    const [isWidthOverConstraint, setIsWidthOverConstraint] =
        React.useState<boolean>(false);

    const [currentAccessory, updateAccessory] = React.useState("handingHooks");
    const getAccessories = getStep3(state.repo);
    const [currentAdditional, updateAdditional] = React.useState("workshopCarts");
    const getAdditional = getStep4(state.repo);
    const dispatch = useDispatch();
    const dealers = state.repo.dealers;
    const hasPanels = configuration.defaultPanels;
    const hasCupboards = configuration.defaultCupboards;
    const hasLighting = configuration.defaultLights;
    const allowLifter =
        state.repo.project === "black" ||
        (state.repo.project === "grey" && !state.configuration.corner);
    const hasLifter = configuration.defaultLifter;
    const lifter = state.repo.basemodules.find((x) => x.isLifter);

    const products = getConfigurationProducts(state.configuration, state.repo);
    const pages = ["SIZES", "MODULES", "ACCESSORIES", "ADDITIONAL", "CART"];
    const [togglePageContent, updateToggleContent] = React.useState("");
    const cartForm = React.createRef<HTMLFormElement>();
    const size = getSize(configuration, state.repo);
    const customSize = getCustomSize(configuration, state.repo);
    const [formSuccess, updateFormSuccess] = React.useState(false);
    const preview = state.cart.sidebar_preview;
    const productLine = getProductLine();

    React.useEffect(() => {
        if (configuration.selected) {
            updatePanel(1);
        }

        if (props.setMobile) {
            panel === -1 ? props.setMobile(true) : props.setMobile(false);
        }
    }, [configuration.selected, panel, props]);

    const previousTab = () => {
        if (panel > 0) {
            const pageId = panel - 1;
            const pageName: PAGES = pages[pageId] as PAGES;
            updatePanel(pageId);
            updatePage(pageId, pageName);
            TrackEvent("STEP", "previous", pageName);
        }
        updateFormSuccess(false);
    };

    const nextTab = () => {
        if (panel < total) {
            const pageId = panel + 1;
            const pageName: PAGES = pages[pageId] as PAGES;
            updatePanel(pageId);
            updatePage(pageId, pageName);
            TrackEvent("STEP", "next", pageName);
        }
        updateFormSuccess(false);
    };

    const setPanel = (id: number) => {
        if (panel === id) {
            updatePanel(-1);
        } else {
            updatePanel(id);
        }
    };

    const updatePage = (id: number, page: PAGES) => {
        if (
            id === panel &&
            (page === "ACCESSORIES" || page === "ADDITIONAL" || page === "CART") &&
            !isMobile()
        ) {
            if (togglePageContent === page) {
                updateToggleContent("");
            } else {
                updateToggleContent(page);
            }
        } else {
            setPanel(id);
            updateToggleContent("");
        }

        if (state.cart.page === "MODULES" && !state.cart.notified) {
            dispatch(changePageHideNotification(page));
        } else if (state.cart.page === "MODULES" && state.cart.notified_module) {
            dispatch(changeModuleHideNotification(page));
        } else {
            dispatch(changePage(page));
        }
        if (state.configuration.selected) {
            dispatch(selectModule(undefined));
        }
        if (preview) {
            dispatch(closeSidebarPreview());
        }
        TrackEvent("STEP", "tab", page.toString());
        updateTab(0);
        updateMenuTab(0);
        updateFormSuccess(false);
    };

    const togglePanels = (repo: RepoState, set: boolean) => {
        dispatch(
            setAllParts(
                "PANEL",
                set ? repo.toolboards.find((t) => !t.isCorner)?.id : undefined,
                set ? repo.toolboards.find((t) => t.isCorner)?.id : undefined
            )
        );
    };

    const toggleCupboards = (repo: RepoState, set: boolean) => {
        dispatch(
            setAllParts(
                "CUPBOARD",
                set ? repo.cupboards.find((t) => !t.isCorner)?.id : undefined,
                set ? repo.cupboards.find((t) => t.isCorner)?.id : undefined
            )
        );
    };

    const toggleLighting = (repo: RepoState, set: boolean) => {
        dispatch(
            setAllParts("LIGHT", set ? "TRUE" : undefined, set ? "TRUE" : undefined)
        );
    };

    const toggleLifter = (repo: RepoState, set: boolean) => {
        var lifterModel = repo.basemodules.find((x) => x.isLifter)?.id;
        dispatch(
            setAllParts("LIFTER", set ? "TRUE" : undefined, set ? "TRUE" : undefined)
        );
        dispatch(
            setAllParts(
                "LIFTERMODEL",
                set ? lifterModel : undefined,
                set ? lifterModel : undefined
            )
        );
    };

    const checkOut = async () => {
        if (cartForm.current?.checkValidity()) {
            TrackEvent("CART", "checkout", "success");
            let storageId = await api.configurationApi.saveConfiguration({
                project: state.repo.project,
                configuration: state.configuration,
                accessories: state.cart.accessories,
                additional: state.cart.additional,
            });
            const cartOrder: Cart = {
                id: storageId,
                customer: {...state.user},
                date: new Date(),
                configimg: state.cart.configimg,
                currency: state.cart.currency,
                language: state.repo.langCode,
                additional: state.cart.additional,
                accessories: state.cart.accessories,
            };
            sendMail({
                configuration: state.configuration,
                repo: state.repo,
                cart: cartOrder,
            });
            updateFormSuccess(true);
            dispatch(clearCartUser());
            //dispatch(addCartImage(""));
        } else {
            cartForm.current?.reportValidity();
        }
    };

    const checkSize = () => {
        if (state.cart.constraint && isWidthOverConstraint) {
            const size = getSize(state.configuration, state.repo);
            const condition = size.width > state.cart.constraint.width;
            return (
                <Warning
                    warningMessage={tokens.CONSTRAINT_WARNING}
                    condition={condition}
                />
            );
        }
        return null;
    };

    React.useEffect(() => {
        if (size.width && state.cart.constraint && state.cart.constraint.width) {
            if (size.width > state.cart.constraint.width) {
                setIsWidthOverConstraint(true);
            } else {
                setIsWidthOverConstraint(false);
            }
        }
    }, [size.width]);

    React.useEffect(() => {
        if (preview) {
            if (state.cart.productType === "ACCESSORIES") {
                const product = getAccessories
                    .map((x) => x.items.filter((y) => y.id === preview.id))
                    .flat();
                if (product.length > 0) {
                    dispatch(updateSidebarPreview(product[0]));
                }
            }
            if (state.cart.productType === "ADDITIONAL") {
                const product = getAdditional
                    .map((x) => x.items.filter((y) => y.id === preview.id))
                    .flat();
                if (product.length > 0) {
                    dispatch(updateSidebarPreview(product[0]));
                }
            }
        }
    }, [state.repo.langCode]);

    const changeSize = (left: number, corner: boolean, right: number) => {
        dispatch(changeModuleSize(state.repo, configuration, left, corner, right));
        dispatch(setCamera(state.configuration.corner ? "OVERVIEW" : "LEFT"));
    };

    const getTableTopImage = (
        material: Material,
        format: "THUMBNAIL" | "LARGE"
    ) => {
        const type = material.toString();
        return getImage(
            state.repo.tabletop.filter((x) => x.material === type)[0]?.imageUrl,
            format
        );
    };

    const getTableTopText = (material: Material) => {
        const type = material.toString();
        return state.repo.tabletop.filter((x) => x.material === type)[0]?.subtitle;
    };
    const modulesLength = (repo: RepoState, modules: Module[]) => {
        if (modules.length <= 1) return modules.length;
        return modules
            .map((m) =>
                repo.basecabinets.find((c) => c.id === m.cabinet)?.isDouble
                    ? 2
                    : (1 as number)
            )
            .reduce((a, b) => a + b);
    };

    const disabledLifter = (): boolean => {
        if (
            state.configuration.left.find(
                (x) => x.panel === "0144-WGB1324K" || x.panel === "0147-WGB1324K-B"
            )
        ) {
            return true;
        }
        if (
            state.configuration.right.find(
                (x) => x.panel === "0144-WGB1324K" || x.panel === "0147-WGB1324K-B"
            )
        ) {
            return true;
        }
        if (
            state.configuration.left.find(
                (x) => x.cabinet === "0244-TSB1300"
            )
        ) {
            return true;
        }
        if (
            state.configuration.right.find(
                (x) => x.cabinet === "0244-TSB1300"
            )
        ) {
            return true;
        }
        if (state.configuration.corner) {
            return true;
        }
        return false;
    };

    const previewUpdate = (id: string, count: number) => {
        const product = getProduct(state.repo, id);
        if (product) {
            switch (state.cart.productType) {
                case "ACCESSORIES":
                    dispatch(updateCartAccessory(product.id, count));
                    break;

                case "ADDITIONAL":
                    dispatch(updateCartAdditional(product.id, count));
                    break;
            }
        }
    };

    const getPreviewInitalValue = (): number => {
        const product = getProduct(state.repo, preview?.id);
        if (product) {
            switch (state.cart.productType) {
                case "ACCESSORIES":
                    if (state.cart.accessories && state.cart.accessories[product.id]) {
                        return state.cart.accessories[product.id];
                    }
                case "ADDITIONAL":
                    if (state.cart.additional && state.cart.additional[product.id]) {
                        return state.cart.additional[product.id];
                    }
            }
        }
        return 0;
    };
    const resetScroll = () => {
        document.querySelector(".panel.active .panel__content")?.scrollTo(0, 0);
    };
    return (
        <>
            <StyledSidebar
                className={`sidebar ${panel !== -1 ? "active" : ""} ${
                    props.toggle ? "toggled" : ""
                }`}
            >
                <Panel
                    total={total}
                    title={tokens.PANEL_TITLE_DIMENSIONS}
                    id={1}
                    active={panel === 0}
                    onClick={() => updatePage(0, "SIZES")}
                >
                    <Slider
                        start={0}
                        total={12}
                        title={tokens.MODULE_COUNT}
                        step={1}
                        value={modulesLength(state.repo, configuration.left)}
                        onChange={(e) =>
                            changeSize(
                                e,
                                configuration.corner != null,
                                modulesLength(state.repo, configuration.right)
                            )
                        }
                    />
                    {state.repo.basemodules.find((m) => m.isCorner) !== undefined ? (
                        <>
                            <h3 style={{padding: "0 25px 0 25px", marginBottom: -15}}>
                                {tokens.CORNER}
                            </h3>
                            <Toggle
                                id="cot"
                                key="cot"
                                title={tokens.INCLUDE_CORNER}
                                checked={configuration.corner != null}
                                onChange={(e) =>
                                    changeSize(
                                        modulesLength(state.repo, configuration.left),
                                        e,
                                        e ? 1 : 0
                                    )
                                }
                                disabled={state.configuration.defaultLifter}
                            />
                            <Slider
                                start={0}
                                disabled={configuration.corner ? false : true}
                                total={12}
                                title={tokens.MODULE_COUNT_RIGHT}
                                step={1}
                                value={modulesLength(state.repo, configuration.right)}
                                onChange={(e) =>
                                    changeSize(
                                        modulesLength(state.repo, configuration.left),
                                        true,
                                        e
                                    )
                                }
                            />
                        </>
                    ) : null}
                    <h3 style={{padding: "0 25px 0 25px", marginBottom: "0px"}}>
                        {tokens.SETTINGS}
                    </h3>
                    <Toggle
                        id="ct"
                        key="ct"
                        title={tokens.INCLUDE_CUPBOARDS}
                        checked={hasCupboards}
                        onChange={(e) => toggleCupboards(state.repo, e)}
                    />
                    <Toggle
                        id="pt"
                        key="pt"
                        title={tokens.INCLUDE_PANELS}
                        checked={hasPanels}
                        onChange={(e) => togglePanels(state.repo, e)}
                        disabled={hasCupboards}
                    />
                    <Toggle
                        id="lighting"
                        key="lig"
                        checked={hasLighting}
                        onChange={() => {
                            toggleLighting(state.repo, !hasLighting);
                        }}
                        title={tokens.INCLUDE_LIGHTING}
                        disabled={!hasCupboards}
                    />
                    <br/>
                    {!allowLifter ? (
                        <></>
                    ) : (
                        <Toggle
                            id="lifter"
                            key="lift"
                            checked={hasLifter}
                            onChange={() => {
                                toggleLifter(state.repo, !hasLifter);
                            }}
                            title={lifter?.title}
                            disabled={disabledLifter()}
                        />
                    )}
                    <br/>
                    <h3
                        style={{
                            padding: "0 25px 0 25px",
                            marginBottom: -15,
                            marginTop: 0,
                        }}
                    >
                        {tokens.MATERIALS}
                    </h3>
                    <div style={{padding: "20px 25px"}}>
                        {Array.from(
                            new Set(state.repo.tabletop.map((t) => t.material))
                        ).map((material) => (
                            <Radio
                                group="group0"
                                key={"fk-" + material}
                                checked={configuration.defaultTabletop === material}
                                disabled={!allowAllTableTops(state.repo, configuration) && material !== "Laminate"}
                                id={"fk-" + material}
                                value={material}
                                onChange={(e) => dispatch(setAllParts("TABLETOP", e, e))}
                            >
                                <img
                                    onClick={() =>
                                        dispatch(showPreview(getTableTopImage(material, "LARGE")))
                                    }
                                    src={getTableTopImage(material, "THUMBNAIL")}
                                    width="60"
                                    style={{
                                        marginRight: "10px",
                                        border: "1px solid rgba(0,0,0,.1)",
                                    }}
                                />
                                <p>{getTableTopText(material)}</p>
                            </Radio>
                        ))}
                    </div>
                </Panel>

                <Panel
                    total={total}
                    title={tokens.PANEL_TITLE_MODULES}
                    id={2}
                    active={panel === 1}
                    onClick={() => updatePage(1, "MODULES")}
                >
                    {!state.configuration.selected ||
                    state.configuration.selected === "NONE" ? (
                        <>
                            <div className="no-border" style={{padding: "20px 25px"}}>
                                <p style={{margin: "0px"}}>
                                    {tokens.MODULES_SELECT_HELP_TEXT}
                                </p>
                            </div>
                        </>
                    ) : (
                        <Details/>
                    )}
                    <div></div>
                </Panel>

                <Panel
                    total={total}
                    title={tokens.PANEL_TITLE_ACCESSORIES}
                    id={3}
                    active={panel === 2}
                    onClick={() => updatePage(2, "ACCESSORIES")}
                >
                    <Tabs
                        changedTab={(e) => {
                            updateMenuTab(e);
                            dispatch(closeSidebarPreview());
                            if (e === 0) {
                                updateTab(0);
                            }
                        }}
                        current={activeMenuTab}
                        items={[
                            tokens.ACCESSORIES,
                            `Results (${
                                getAccessories.filter((x) => x.id === currentAccessory)[0]
                                    ?.items.length
                            })`,
                        ]}
                    />
                    <p style={{padding: "10px 25px 0px 25px"}}>
                        {tokens.ACCESSORIES_DESC}
                    </p>
                    <div style={{padding: "0px 25px"}}>
                        {getAccessories
                            .filter((a) => a.items.length > 0)
                            .map((e, index) => (
                                <Radio
                                    group="groupac"
                                    checked={currentAccessory === e.id ? true : false}
                                    onChange={() => {
                                        updateAccessory(e.id);
                                        updateToggleContent("");
                                        updateMenuTab(1);
                                        resetScroll();
                                        dispatch(closeSidebarPreview());
                                        updateTab(0);
                                    }}
                                    id={"g" + index}
                                    key={"g" + index}
                                    value={e.id}
                                >
                                    <div
                                        key={"d" + index}
                                        onClick={() => {
                                            updateToggleContent("");
                                            dispatch(closeSidebarPreview());
                                        }}
                                    >
                                        <p className="single-text">{e.title}</p>
                                    </div>
                                </Radio>
                            ))}
                    </div>
                </Panel>


                <Panel
                    total={total}
                    title={tokens.PANEL_TITLE_ADDITIONAL}
                    id={4}
                    active={panel === 3}
                    onClick={() => updatePage(3, "ADDITIONAL")}
                >
                    <Tabs
                        changedTab={(e) => {
                            updateMenuTab(e);
                            dispatch(closeSidebarPreview());
                            if (e === 0) {
                                updateTab(0);
                            }
                        }}
                        current={activeMenuTab}
                        items={[
                            tokens.ADDITIONAL,
                            `Results (${
                                getAdditional.filter((x) => x.id === currentAdditional)[0]
                                    ?.items.length
                            })`,
                        ]}
                    />
                    <p style={{padding: "10px 25px 0 25px"}}>
                        {tokens.ADDITIONAL_DESC}
                    </p>
                    <div style={{padding: "20px 25px", paddingTop: 0}}>
                        {getAdditional.map((e, index) => (
                            <Radio
                                group="groupad"
                                checked={currentAdditional === e.id ? true : false}
                                onChange={() => {
                                    updateAdditional(e.id);
                                    updateToggleContent("");
                                    updateMenuTab(1);
                                    resetScroll();
                                    dispatch(closeSidebarPreview());
                                    updateTab(0);
                                }}
                                id={"ag" + index}
                                key={"ag" + index}
                                value={e.id}
                            >
                                <div
                                    key={"ad" + index}
                                    onClick={() => {
                                        updateToggleContent("");
                                        dispatch(closeSidebarPreview());
                                    }}
                                >
                                    <p className="single-text">{e.title}</p>
                                </div>
                            </Radio>
                        ))}
                    </div>
                </Panel>


                <SidebarCart
                    tokens={tokens}
                    dealers={dealers}
                    countries={state.repo.countries}
                    user={state.user}
                    langCode={state.repo.langCode}
                    formSuccess={formSuccess}
                    panel={panel}
                    total={total}
                    cartForm={cartForm}
                    activeMenuTab={activeMenuTab}
                    updateMenuTab={updateMenuTab}
                    updatePage={updatePage}
                    previousTab={previousTab}
                    nextTab={nextTab}
                    checkOut={checkOut}
                />

                <StyledSidebarNav className="sidebar--checkout">
                    <IconButton icon="arrow-left" onClick={previousTab}/>
                    <Button
                        title={
                            panel === total
                                ? !formSuccess
                                    ? tokens.CHECKOUT
                                    : ""
                                : tokens.NEXT
                        }
                        onClick={panel === total && !formSuccess ? checkOut : nextTab}
                    />
                </StyledSidebarNav>

                {checkSize()}
            </StyledSidebar>

            {preview ? (
                <Content className="preview" size={"large"} style={{zIndex: 4}}>
                    <SidebarPreviewHeader>
                        <div>
                            <Stepper
                                id={"sb0"}
                                key={"sb0"}
                                className="preview-stepper"
                                max={99}
                                value={getPreviewInitalValue()}
                                onChange={(step) => previewUpdate(preview.id, step)}
                                showPrice={{
                                    text: tokens.PRICE,
                                    price: getItemPrice(
                                        preview,
                                        state.cart.currency,
                                        state.repo.langCode
                                    ),
                                }}
                            >
                                <div key={"d0"}>
                                    <h6 style={{fontSize: "11px"}}>{preview.id}</h6>
                                    <h4>{preview.title}</h4>
                                </div>
                            </Stepper>
                            <IconButton
                                icon="close"
                                className="alternate close"
                                onClick={() => dispatch(closeSidebarPreview())}
                            />
                        </div>
                        <div>
                            {preview.size ? (
                                <>
                                    <span>{tokens.DIMENSIONS}: </span>
                                    <strong>
                                        {preview.size.width} x {preview.size.height} x{" "}
                                        {preview.size.depth} {tokens.UNITS}
                                    </strong>
                                </>
                            ) : null}
                            <span
                                style={{whiteSpace: "pre-line", fontSize: "14px"}}
                                dangerouslySetInnerHTML={{
                                    __html: (preview.extended_text
                                            ? preview.extended_text
                                            : preview.subtitle
                                                ? preview.subtitle
                                                : ""
                                    ).replace(/¤/g, "<span class='bullet'>&bull;</span>"),
                                }}
                            />
                        </div>
                    </SidebarPreviewHeader>
                    <SidebarPreviewContainer style={{padding: "10px"}}>
                        <img
                            alt="preview"
                            src={getImage(preview.imageUrl, "MEDIUM")}
                            onClick={() => {
                                if (preview && preview.imageUrl) {
                                    dispatch(showPreview(getImage(preview.imageUrl, "LARGE")));
                                }
                            }}
                        />
                    </SidebarPreviewContainer>
                </Content>
            ) : null}

            {state.cart.page === "ACCESSORIES" &&
            togglePageContent !== "ACCESSORIES" &&
            !configuration.selected ? (
                <Content
                    size={"large"}
                    tabbed={activeTab === 1 || activeMenuTab === 1 ? true : false}
                >
                    <Tabs
                        changedTab={(e) => updateTab(e)}
                        current={activeTab}
                        items={[
                            tokens.ALL,
                            `${tokens.MINE} (${
                                state.cart.accessories
                                    ? Object.keys(state.cart.accessories).reduce(
                                        (a, b) =>
                                            a +
                                            (state.cart.accessories
                                                ? state.cart.accessories[b]
                                                : 0),
                                        0
                                    )
                                    : 0
                            })`,
                        ]}
                    />
                    {activeTab === 0 ? (
                        <>
                            <Search onSearch={setAccessoriesSearch}/>
                            <div
                                style={{
                                    padding: "20px 25px",
                                    display: activeMenuTab === 1 ? "block" : "",
                                }}
                            >
                                {state.repo.step3[currentAccessory]?.items
                                    .filter((p) => searchProduct(accessoriesSearch, p))
                                    .map((e: Product, ei) => {
                                        const val =
                                            state.cart.accessories && state.cart.accessories[e.id]
                                                ? state.cart.accessories[e.id]
                                                : 0;
                                        return (
                                            <Stepper
                                                id={"k" + ei}
                                                key={"k" + e.id}
                                                value={val}
                                                max={99}
                                                showPrice={{
                                                    text: tokens.PRICE,
                                                    price: getItemPrice(
                                                        e,
                                                        state.cart.currency,
                                                        state.repo.langCode
                                                    ),
                                                }}
                                                onChange={(step) =>
                                                    dispatch(updateCartAccessory(e.id, step))
                                                }
                                            >
                                                <img
                                                    src={getImage(e.imageUrl, "THUMBNAIL")}
                                                    width="80"
                                                    key={"i" + ei}
                                                    height="80"
                                                    alt={e.title}
                                                    style={{
                                                        marginRight: "10px",
                                                        cursor: "pointer",
                                                        minWidth: "80px",
                                                        minHeight: "80px",
                                                    }}
                                                    onClick={() =>
                                                        dispatch(showSidebarPreview(e, "ACCESSORIES"))
                                                    }
                                                />
                                                <div
                                                    className="product-item"
                                                    key={"d" + ei}
                                                    onClick={() =>
                                                        dispatch(showSidebarPreview(e, "ACCESSORIES"))
                                                    }
                                                >
                                                    <h6>{e.id}</h6>
                                                    <h4>{e.title}</h4>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: e.subtitle ? e.subtitle : "",
                                                        }}
                                                    >
                                                        {}
                                                    </p>
                                                </div>
                                            </Stepper>
                                        );
                                    })}
                            </div>
                        </>
                    ) : (
                        <div style={{padding: "20px 25px"}}>
                            {state.cart.accessories
                                ? Object.keys(state.cart.accessories).map((id, ei) => {
                                    const item = getProduct(state.repo, id);
                                    const val = state.cart.accessories
                                        ? state.cart.accessories[id]
                                        : 0;
                                    return item ? (
                                        <Stepper
                                            id={"k" + ei}
                                            value={val}
                                            key={"k" + id}
                                            max={99}
                                            onChange={(step) =>
                                                dispatch(updateCartAccessory(id, step))
                                            }
                                            showPrice={{
                                                text: tokens.PRICE,
                                                price: getItemPrice(
                                                    item,
                                                    state.cart.currency,
                                                    state.repo.langCode
                                                ),
                                            }}
                                        >
                                            <img
                                                src={getImage(item.imageUrl, "THUMBNAIL")}
                                                width="80"
                                                key={"i" + ei}
                                                height="80"
                                                alt={item.title}
                                                style={{
                                                    marginRight: "10px",
                                                    cursor: "pointer",
                                                    minWidth: "80px",
                                                    minHeight: "80px",
                                                }}
                                                onClick={() =>
                                                    dispatch(showSidebarPreview(item, "ACCESSORIES"))
                                                }
                                            />
                                            <div
                                                className="product-item"
                                                key={"d" + ei}
                                                onClick={() =>
                                                    dispatch(showSidebarPreview(item, "ACCESSORIES"))
                                                }
                                            >
                                                <h6>{item.id}</h6>
                                                <h4>{item.title}</h4>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.subtitle ? item.subtitle : "",
                                                    }}
                                                >
                                                    {}
                                                </p>
                                            </div>
                                        </Stepper>
                                    ) : null;
                                })
                                : null}
                        </div>
                    )}
                </Content>
            ) : null}
            {state.cart.page === "ADDITIONAL" &&
            togglePageContent !== "ADDITIONAL" &&
            !configuration.selected ? (
                <Content
                    size={"large"}
                    tabbed={activeTab === 1 || activeMenuTab === 1 ? true : false}
                >
                    <Tabs
                        changedTab={(e) => updateTab(e)}
                        current={activeTab}
                        items={[
                            tokens.ALL,
                            `${tokens.MINE} (${
                                state.cart.additional
                                    ? Object.keys(state.cart.additional).reduce(
                                        (a, b) =>
                                            a +
                                            (state.cart.additional ? state.cart.additional[b] : 0),
                                        0
                                    )
                                    : 0
                            })`,
                        ]}
                    />
                    {activeTab === 0 ? (
                        <>
                            {" "}
                            <Search onSearch={setAdditionalSearch}/>
                            <div style={{padding: "20px 25px"}}>
                                {state.repo.step4[currentAdditional]?.items
                                    .filter((p) => searchProduct(additionalSearch, p))
                                    .map((e: Product, ei) => {
                                        const val =
                                            state.cart.additional && state.cart.additional[e.id]
                                                ? state.cart.additional[e.id]
                                                : 0;
                                        return (
                                            <Stepper
                                                id={"ak" + ei}
                                                value={val}
                                                key={"ak" + e.id + ei}
                                                max={99}
                                                onChange={(step) =>
                                                    dispatch(updateCartAdditional(e.id, step))
                                                }
                                                showPrice={{
                                                    text: tokens.PRICE,
                                                    price: getItemPrice(
                                                        e,
                                                        state.cart.currency,
                                                        state.repo.langCode
                                                    ),
                                                }}
                                            >
                                                <img
                                                    src={getImage(e.imageUrl, "THUMBNAIL")}
                                                    width="80"
                                                    key={"ai" + ei}
                                                    height="80"
                                                    alt={e.title}
                                                    style={{
                                                        marginRight: "10px",
                                                        cursor: "pointer",
                                                        minWidth: "80px",
                                                        minHeight: "80px",
                                                    }}
                                                    onClick={() =>
                                                        dispatch(showSidebarPreview(e, "ADDITIONAL"))
                                                    }
                                                />
                                                <div
                                                    className="product-item"
                                                    key={"d" + ei}
                                                    onClick={() =>
                                                        dispatch(showSidebarPreview(e, "ADDITIONAL"))
                                                    }
                                                >
                                                    <h6>{e.id}</h6>
                                                    <h4>{e.title}</h4>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: e.subtitle ? e.subtitle : "",
                                                        }}
                                                    >
                                                        {}
                                                    </p>
                                                </div>
                                            </Stepper>
                                        );
                                    })}
                            </div>
                        </>
                    ) : (
                        <div style={{padding: "20px 25px"}}>
                            {state.cart.additional
                                ? Object.keys(state.cart.additional).map((id, ei) => {
                                    const item = getProduct(state.repo, id);
                                    const val = state.cart.additional
                                        ? state.cart.additional[id]
                                        : 0;
                                    return item ? (
                                        <Stepper
                                            id={"ak" + ei}
                                            value={val}
                                            key={"ak" + id + ei}
                                            max={99}
                                            onChange={(step) =>
                                                dispatch(updateCartAdditional(id, step))
                                            }
                                            showPrice={{
                                                text: tokens.PRICE,
                                                price: getItemPrice(
                                                    item,
                                                    state.cart.currency,
                                                    state.repo.langCode
                                                ),
                                            }}
                                        >
                                            <img
                                                src={getImage(item.imageUrl, "THUMBNAIL")}
                                                width="80"
                                                key={"ai" + ei}
                                                height="80"
                                                alt={item.title}
                                                style={{
                                                    marginRight: "10px",
                                                    cursor: "pointer",
                                                    minWidth: "80px",
                                                    minHeight: "80px",
                                                }}
                                                onClick={() =>
                                                    dispatch(showSidebarPreview(item, "ADDITIONAL"))
                                                }
                                            />
                                            <div
                                                className="product-item"
                                                key={"d" + ei}
                                                onClick={() =>
                                                    dispatch(showSidebarPreview(item, "ADDITIONAL"))
                                                }
                                            >
                                                <h6>{item.id}</h6>
                                                <h4>{item.title}</h4>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.subtitle ? item.subtitle : "",
                                                    }}
                                                >
                                                    {}
                                                </p>
                                            </div>
                                        </Stepper>
                                    ) : null;
                                })
                                : null}
                        </div>
                    )}
                </Content>
            ) : null}
            <CartPage
                cart={state.cart}
                repo={state.repo}
                configuration={state.configuration}
                activeTab={activeTab}
                activeMenuTab={activeMenuTab}
                tokens={tokens}
                togglePageContent={togglePageContent}
                size={size}
            />
            {(state.cart.page === "ACCESSORIES" ||
                state.cart.page === "ADDITIONAL" ||
                state.cart.page === "CART") &&
            togglePageContent === "" ? (
                <HideContent
                    className="hide-content"
                    onClick={() => {
                        updateToggleContent(state.cart.page);
                        dispatch(closeSidebarPreview());
                    }}
                />
            ) : null}
        </>
    );
};

export default Sidebar;
