import * as React from "react";
import { StyledStep, StyledButton, StyledInput} from "./Stepper.style";

interface DisplayPrice{
    text: string;
    price: string;
}

interface Props{
    max: number;
    title?: string;
    step?: number;
    value?: number;
    id?: string;
    onChange?: (e:number) => void;
    showPrice?: DisplayPrice;
    className?: string;
    children?: React.ReactNode;
};

interface State{
    current: number;
}


const Stepper:React.FC<Props> = (props) =>
{
    const [current, updateCurrent] = React.useState(props.value ? props.value : 0);
    const refSelect = React.createRef<HTMLInputElement>();

    const change = (e:React.SyntheticEvent<HTMLInputElement>) =>
    {
        const val = parseInt(e.currentTarget.value);
        updateCurrent(val);
        if(props.onChange)
        {
            if(isNaN(val))
            {
                props.onChange(0);
            }
            else
            {
                props.onChange(val);
            }
        }
    }

    const decrease = () =>
    {
        if(current > 0){
            const val = current - (props.step ? props.step  : 1);
            updateCurrent(val);
            if(props.onChange)
            {
                props.onChange(val);
            }
        }else{
            updateCurrent(0);
            if(props.onChange)
            {
                props.onChange(0);
            }
        }

    }

    const increase = () =>
    {
        if(current < props.max){
            const val = current + (props.step ? props.step  : 1);
            updateCurrent(val);
            if(props.onChange)
            {
                props.onChange(val);
            }
        }
    }
    const setSelect = () =>
    {
        if(refSelect.current)
        {
            refSelect.current.selectionStart = 99;
        }
    }

    return <StyledStep className={`${props.className ? props.className : ""}`}>
        <>
            {props.title ? <h3>{props.title}</h3> : null}
            {props.children}
            <div className="stepper-holder">
                <div className="steppers">
                    <StyledButton disabled={current === 0 ? true : false} className="left"
                                  onClick={decrease}><span>-</span></StyledButton>
                    <StyledInput type="tel" pattern="\d*" id={"stepper-" + props.id} key={1} placeholder="0" min={0}
                                 max={props.max} step={props.step}
                                 value={props.value === 0 ? "" : props.value} onChange={change} ref={refSelect}
                                 onClick={setSelect}/>
                    <StyledButton disabled={current >= props.max ? true : false} className="right"
                                  onClick={increase}><span>+</span></StyledButton>
                </div>
                {props.showPrice ? <div className="price">
                <span>
                    {props.showPrice.text}:
                </span>
                    <span>
                    {props.showPrice.price}
                </span>
                </div> : null}
            </div>
        </>
    </StyledStep>;

};


export default Stepper;
