import {Dictionary, RepoState, Size} from 'typings/repo';
import {ConfigurationState, Module} from 'typings/configuration';
import {getModuleList} from 'state/configuration';
import {getProduct, getRandomModule, Material} from 'state/repo';
import {setConfiguration} from 'state/configuration/actions';
import {IsNeighborCabinetEmpty, IsNeighborCabinetNotBase} from 'components/layout/Sidebar/Details';

export const endBracketGrey = "0144-WGB1375A";
export const betweenBracketGrey = "0144-WGB1375";
export const endBracketBlack = "0147-WGB1375A-B";
export const betweenBracketBlack = "0147-WGB1375-B";
export const lightKits = ["9001-LIGHTKIT1", "9001-LIGHTKIT2", "9001-LIGHTKIT3"];
export const XLCart = "0144-TGC1307WB";
export const EmptyDoubleSpace = "0244-TSB1300";


export const setModulesFromMeasurements = (repo: RepoState, left: number, right: number) => {
    var oriLeft = left;
    var oriRight = right;
    let corner = false;
    var defaultMaterial = repo.tabletop[0].material;
    var defaultLifterModel = repo.basemodules.find(x => x.isLifter)?.id;
    let configuration: ConfigurationState = {
        left: [],
        right: [],
        defaultTabletop: defaultMaterial,
        defaultCupboards: true,
        defaultLights: false,
        defaultLifter: false,
        defaultPanels: true,
        defaultLifterModel: defaultLifterModel !== undefined ? defaultLifterModel : ""
    };
    var cornerLength = repo.basemodules.find(m => m.isCorner)?.size?.width ?? 865;
    var cabinetLength = repo.basemodules.find(m => !m.isBaseModule && !m.isCorner && m.isDouble !== true)?.size?.width ?? 600;
    var moduleLength = repo.basemodules.find(m => m.isBaseModule && !m.isCorner && m.isDouble !== true)?.size?.width ?? 680;
    if (right >= cornerLength + moduleLength && left >= cornerLength + moduleLength && repo.project !== "hd") { // size of corner plus 1 module
        right -= cornerLength ?? 0;
        left -= cornerLength ?? 0;
        corner = true;
    }
    let leftCount = 0;
    let rightCount = 0;
    if (left >= cabinetLength + moduleLength) { // calculate for tall module of width 600 to be included
        leftCount += 2;
        left -= cabinetLength + moduleLength;
    }
    if (right >= cabinetLength + moduleLength) { // calculate for tall module of width 600 to be included
        rightCount += 2;
        right -= cabinetLength + moduleLength;
    }
    leftCount += Math.floor(left / moduleLength);
    rightCount += Math.floor(right / moduleLength);
    if (leftCount > 12) leftCount = 12;
    if (rightCount > 12) rightCount = 12;
    var result = changeModuleSize(repo, configuration, leftCount, corner, corner ? rightCount : 0);
    var size = getSize(configuration, repo);
    while (size.width > oriLeft) {
        changeModuleSize(repo, configuration, --leftCount, corner, corner ? rightCount : 0);
        size = getSize(configuration, repo);
    }
    while (oriRight > 0 && (size.width2 ?? 0) > oriRight) {
        changeModuleSize(repo, configuration, leftCount, corner, corner ? --rightCount : 0);
        size = getSize(configuration, repo);
    }

    return result;
}

export const setDefaultModules = (repo: RepoState) => {
    var defaultMaterial = repo.tabletop[0].material;
    var defaultLifterModel = repo.basemodules.find(x => x.isLifter)?.id;

    let configuration: ConfigurationState = {
        left: [],
        right: [],
        defaultTabletop: defaultMaterial,
        defaultCupboards: true,
        defaultLights: false,
        defaultLifter: false,
        defaultPanels: true,
        defaultLifterModel: defaultLifterModel !== undefined ? defaultLifterModel : ""
    };
    return changeModuleSize(repo, configuration, 4, false, 0);
}

export const changeModuleSize = (repo: RepoState, configuration: ConfigurationState, left: number, corner: boolean, right: number) => {
    const tabletop = configuration.defaultTabletop;
    const hasLight = configuration.defaultLights;
    const hasLifter = configuration.defaultLifter;
    const hasCupboard = configuration.defaultCupboards;
    const hasPanel = configuration.defaultPanels;
    if (corner !== (configuration.corner !== undefined)) {
        if (corner) {
            var module = getRandomModule(repo, configuration, true, tabletop);
            module.cupboard = hasCupboard ? module.cupboard : undefined;
            module.panel = hasPanel ? repo.toolboards.filter(t => t.isCorner)[0].id : undefined;
            module.light = hasLight;
            module.isLifted = hasLifter;
            configuration.corner = module;
        } else {
            configuration.corner = undefined;
        }
    }
    configuration.left = adjustModuleListSize(repo, configuration, configuration.left, left, tabletop, true, hasPanel, hasCupboard, hasLight, hasLifter, configuration.defaultLifterModel);
    configuration.right = adjustModuleListSize(repo, configuration, configuration.right, right, tabletop, true, hasPanel, hasCupboard, hasLight, hasLifter, configuration.defaultLifterModel);
    return setConfiguration(configuration);
}

function modulesLength(modules: Module[], lengths: { [x: string]: number }) {
    if (modules.length === 0) return 0;
    return modules.map(m => lengths[m.cabinet ?? m.base] ?? 1).reduce((a, b) => a + b);
}

const adjustModuleListSize = (repo: RepoState, configuration: ConfigurationState, modules: Module[], length: number, tabletop: Material, endingModule: boolean, hasPanel: boolean, hasCupboard: boolean, hasLight: boolean, hasLifter: boolean, lifter: string): Module[] => {
    if (length === 0) return [];
    var moduleLengths = Object.assign({}, ...repo.basecabinets.map(m => ({[m.id]: m.isDouble === true ? 2 : 1})));
    while (modulesLength(modules, moduleLengths) > length) {
        const deleteFrom = length <= 2 ? length === 1 ? 0 : 1 : (length - 2); // Delete modules apart from ending when length >= 2
        if (modules.splice(deleteFrom, 1).length === 0) break;
    }
    if (modulesLength(modules, moduleLengths) < length) {
        if (endingModule && modules.length <= 1 && length > 1) { // Add ending module
            modules.push(getRandomModule(repo, configuration, false, tabletop, true, false, false));
        }
        while (modulesLength(modules, moduleLengths) < length) {
            var canDouble = modules.length !== 0 && length - modulesLength(modules, moduleLengths) >= 2;
            var index = Math.floor(Math.random() * (modules.length - 2)) + 1;
            var canCart = modules.length > 2 && modules[index].canCart && !isCartModule(repo, modules[index]) && allowAllTableTops(repo, configuration);
            var module = getRandomModule(repo, configuration, false, tabletop, false, canCart, canDouble);

            module.cupboard = hasCupboard ? module.cupboard : undefined;
            module.panel = hasPanel ? repo.toolboards.filter(t => !t.isCorner)[0].id : undefined;
            module.light = hasLight;
            module.isLifted = hasLifter;
            module.lifter = lifter;
            modules.splice(!endingModule ? modules.length : modules.length < 2 ? 0 : index, 0, module);
            validateModules(repo, modules, false, tabletop);
        }
    }
    return modules;
}

/* Validates and corrects mistakes in configuration state*/
export const validateState = (repo: RepoState, configuration: ConfigurationState) => {
    const hasCorner = configuration.corner !== undefined && configuration.corner.cupboard !== undefined;

    validateModules(repo, configuration.left, hasCorner, configuration.defaultTabletop);
    validateModules(repo, configuration.right, hasCorner, configuration.defaultTabletop);

    validateXLCartLaminate(repo, configuration, configuration.left);
    validateXLCartLaminate(repo, configuration, configuration.right);

    // if( configuration.defaultTabletop !== "Laminate" && repo?.project !== undefined && repo.project === "grey") {
    //     validateTableTop(configuration, configuration.left);
    //     validateTableTop(configuration, configuration.right);
    // }
    if (configuration.corner) {
        const module = configuration.corner;
        module.panel = module.panel || module.cupboard !== undefined ? repo.toolboards.filter(t => t.isCorner)[0].id : undefined;
        module.light = module.light && module.cupboard !== undefined;
        module.canCart = module.canFull = false;
        module.canCupboard = (configuration.left.length === 0 || configuration.left[0].base === repo.basemodules.filter(t => !t.isCorner && t.isBaseModule)[0].id) &&
            (configuration.right.length === 0 || configuration.right[0].base === repo.basemodules.filter(t => !t.isCorner && t.isBaseModule)[0].id);
        module.cupboard = module.canCupboard ? module.cupboard : undefined;
    }
}
const validateXLCartLaminate = (repo: RepoState, configuration: ConfigurationState, modules: Module[]) => {
    let check = false;
    for (var i = 0; i < modules.length; i++) {
        const module = modules[i];
        if (module.cabinet === XLCart) {
            check = ((IsNeighborCabinetEmpty(repo, configuration, module.id, false) === -1 || !IsNeighborCabinetNotBase(repo, configuration, module.id)) && i !== 0 && i !== modules.length - 1);
            if (check) {
                module.isValidXLCart = true;
            }
        }
    }
    return check;
}

export const allowAllTableTops = (repo: RepoState, configuration: ConfigurationState) => {
    let modules = configuration.left;

    for (var i = 0; i < modules.length; i++) {
        const module = modules[i];
        if (module.cabinet === undefined) {
            let idx = IsNeighborCabinetEmpty(repo, configuration, module.id, false, true);
            if (idx !== -1) {
                return false;
            }
        }
    }

    modules = configuration.right;
    for (var i = 0; i < modules.length; i++) {
        const module = modules[i];
        if (module.cabinet === undefined) {
            let idx = IsNeighborCabinetEmpty(repo, configuration, module.id, false, true);
            if (idx !== -1) {
                return false;
            }
        }
    }
    return true;
}
/* Validates and corrects mistakes in module list*/
const validateModules = (repo: RepoState, modules: Module[], hasCorner: boolean, defaultTabletop: Material) => {
    const lastIndex = modules.length - 1;
    for (var i = 0; i < modules.length; i++) {
        const module = modules[i]
        const isModule = repo.basemodules.find(m => m.id === module.base && m.isBaseModule) !== undefined; // is a modular set and not a full cabinet
        module.tabletop = isModule ? module.tabletop || defaultTabletop : undefined; // only provide tabletop when it is a modular set


        module.cupboard = isModule ? module.cupboard : undefined; // only provide cupboard when it is a modular set
        module.panel = isModule && module.panel !== undefined || module.cupboard !== undefined ? module.panel || repo.toolboards.filter(t => t.isCorner)[0].id : undefined; // force enabled panel when cupboard is set
        module.light = module.light && module.cupboard !== undefined; // force disabled light when no cupboard is set
        module.canCupboard = true;

        module.canFull = (!hasCorner || i !== 0) && (i === 0 || !isCartModule(repo, modules[i - 1])) && (i === lastIndex || !isCartModule(repo, modules[i + 1])); // boolean to check that a full cabinet is possible
        module.canCart = module.canFull && i !== 0 && i !== lastIndex &&
            !isFullModule(repo, modules[i - 1]) && !isFullModule(repo, modules[i + 1]) &&
            (i < 3 || !isCartModule(repo, modules[i - 2])) && (i > lastIndex - 3 || !isCartModule(repo, modules[i + 2])); // boolean to check that cart or empty cabinet is possible
        if (!module.canCart && isCartModule(repo, module)) { // reset to default cabinet in case of error
            module.base = repo.basemodules.filter(m => m.isBaseModule && !m.isCorner)[0].id;
            module.cabinet = repo.basecabinets.filter(m => !m.isCorner)[0]?.id;
        } else if (!module.canFull && !isModule) {
            module.cabinet = repo.basecabinets.filter(m => !m.isCorner)[0]?.id;
            module.tabletop = defaultTabletop;
        } else if (!isModule) {
            module.cabinet = undefined;
        }

        if (module.tabletop === undefined) {
            module.lifter = undefined;
            module.isLifted = false;
        }
    }
}

const isCartModule = (repo: RepoState, module: Module) => {
    return repo.allProducts[module.cabinet ?? ""]?.isCart === true;
}

const isFullModule = (repo: RepoState, module: Module) => {
    return repo.allProducts[module.base].isBaseModule === false;
}

export const getConfigurationProducts = (configuration: ConfigurationState, repo: RepoState) => {
    let parts: Dictionary<number> = {};
    let modules = getModuleList(configuration);


    modules.forEach(m => {
        let isBaseModule = repo.basemodules.find(b => b.id === m.base)?.isBaseModule;
        let isDouble = repo.basecabinets.find(b => b.id === m.cabinet)?.isDouble;
        if (!isBaseModule) {
            addPartToDictionary(parts, m.base);
        }
        addPartToDictionary(parts, m.cabinet);
        addPartToDictionary(parts, m.cupboard);
        addPartToDictionary(parts, m.panel);
        if (isDouble) {
            addPartToDictionary(parts, m.cupboard);
            addPartToDictionary(parts, m.panel);
        }
        if (m.isLifted && m.lifter !== undefined && m.cabinet !== undefined && m.cabinet !== EmptyDoubleSpace && m.cabinet !== "0144-TGC1307WB" && m.cabinet !== "0144-TGC1305W") {
            addPartToDictionary(parts, m.lifter);
            if (isDouble) {
                addPartToDictionary(parts, m.lifter);
            }
        }
        if (m.cabinet === XLCart) {
            if (m.tabletop !== "Laminate") {
                addPartToDictionary(parts, EmptyDoubleSpace);
            } else if (!m.isValidXLCart) {
                addPartToDictionary(parts, EmptyDoubleSpace);
            }
        }
    });

    if (configuration.corner && configuration.corner.panel) parts[configuration.corner.panel] += 1;
    addTabletops(configuration, repo, parts);
    addLights(repo, configuration, parts);
    addBrackets(repo, configuration, parts);


    return parts;
}

function addPartToDictionary(dic: Dictionary<number>, part?: string) {
    if (part) dic[part] = dic[part] ? dic[part] + 1 : 1;
}

function addBrackets(repo: RepoState, configuration: ConfigurationState, dic: Dictionary<number>) {
    if (repo.project === "hd") {
        var count = (dic["0145-TBCL345"] ?? 0);
        if (count > 0) {
            dic["0145-TBCL316"] = count;
            dic["0145-TBCL3200"] = count;
        }
        return;
    } else if (repo.project === "black") {
        dic[endBracketBlack] = 0;
        dic[betweenBracketBlack] = 0;
        abfaBlack(repo, configuration.left, dic);
        abfaBlack(repo, configuration.right, dic);
        if (configuration.corner && configuration.corner.panel) {
            dic[endBracketBlack] += 4; // add 4 brackets for corner panel
            if (configuration.left.length > 0 && configuration.left[0].panel === repo.toolboards.filter(t => !t.isCorner)[0].id) {
                // replace 2 endbrackets with a betweenbracket when left side starts with a panel
                dic[endBracketBlack] -= 2;
                dic[betweenBracketBlack] += 1;
            }
            if (configuration.right.length > 0 && configuration.right[0].panel === repo.toolboards.filter(t => !t.isCorner)[0].id) {
                // replace 2 endbrackets with a betweenbracket when right side starts with a panel
                dic[endBracketBlack] -= 2;
                dic[betweenBracketBlack] += 1;
            }
        }
    } else {
        dic[endBracketGrey] = 0;
        dic[betweenBracketGrey] = 0;
        abfaGrey(repo, configuration.left, dic);
        abfaGrey(repo, configuration.right, dic);
        if (configuration.corner && configuration.corner.panel) {
            dic[endBracketGrey] += 4; // add 4 brackets for corner panel
            if (configuration.left.length > 0 && configuration.left[0].panel === repo.toolboards.filter(t => !t.isCorner)[0].id) {
                // replace 2 endbrackets with a betweenbracket when left side starts with a panel
                dic[endBracketGrey] -= 2;
                dic[betweenBracketGrey] += 1;
            }
            if (configuration.right.length > 0 && configuration.right[0].panel === repo.toolboards.filter(t => !t.isCorner)[0].id) {
                // replace 2 endbrackets with a betweenbracket when right side starts with a panel
                dic[endBracketGrey] -= 2;
                dic[betweenBracketGrey] += 1;
            }
        }
    }
}

function abfaGrey(repo: RepoState, modules: Module[], dic: Dictionary<number>) {
    let baseModule = repo.basemodules.filter(m => m.isBaseModule && !m.isCorner)[0]?.id;
    let partPanels = repo.toolboards.filter(t => !t.isCorner && t.id !== "0144-GB03").map(t => t.id);
    let lastBase: string = baseModule;
    let lastPanel: boolean = false;
    modules.forEach(m => {
        let isPanel = partPanels.indexOf(m.panel ?? '') !== -1;
        let isDouble = repo.basecabinets.find(b => b.id === m.cabinet)?.isDouble;
        if (isDouble && isPanel) {
            dic[betweenBracketGrey] += 1; // add a bracket between double module if it has panels
        }
        if (lastPanel !== isPanel) {
            if (lastBase !== m.base) {
                dic[betweenBracketGrey] += 1; // going from base module with panel to full cabinet or vise versa
            } else {
                dic[endBracketGrey] += 1; // going from base module with panel to base module without panel or vise versa
            }
        } else {
            if (isPanel || (lastBase !== baseModule && m.base !== baseModule)) {
                if (lastPanel && isPanel) {
                    dic[betweenBracketGrey] += 1; // between 2 panels and not between 2 full cabinets
                }
            }
        }
        lastPanel = isPanel;
        lastBase = m.base;
    });
    if (lastPanel) {
        // add ending bracket in case of a panel
        dic[endBracketGrey] += 1;
    }
}

function abfaBlack(repo: RepoState, modules: Module[], dic: Dictionary<number>) {
    let baseModule = repo.basemodules.filter(m => m.isBaseModule && !m.isCorner)[0]?.id;
    let partPanels = repo.toolboards.filter(t => !t.isCorner && t.id !== "0144-GB03").map(t => t.id);
    let lastBase: string = baseModule;
    let lastPanel: boolean = false;
    modules.forEach(m => {
        let isPanel = partPanels.indexOf(m.panel ?? '') !== -1;
        let cab = repo.basecabinets.find(b => b.id === m.cabinet);
        let isDouble = repo.basecabinets.find(b => b.id === m.cabinet)?.isDouble;
        if (isDouble && isPanel) {
            dic[betweenBracketBlack] += 1; // add a bracket between double module if it has panels
        }
        if (lastPanel !== isPanel) {
            if (lastBase !== m.base) {
                dic[betweenBracketBlack] += 1; // going from base module with panel to full cabinet or vise versa
            } else {
                dic[endBracketBlack] += 1; // going from base module with panel to base module without panel or vise versa
            }
        } else {
            if (isPanel || (lastBase !== baseModule && m.base !== baseModule)) {
                if (lastPanel && isPanel) {
                    dic[betweenBracketBlack] += 1; // between 2 panels and not between 2 full cabinets
                }
            }
        }
        lastPanel = isPanel;
        lastBase = m.base;
    });
    if (lastPanel) {
        // add ending bracket in case of a panel
        dic[endBracketBlack] += 1;
    }
}


function addLights(repo: RepoState, configuration: ConfigurationState, dic: Dictionary<number>) {
    let modules = getModuleList(configuration);
    alfa(repo, modules, dic);
}

// add lights from array (helper function)
function alfa(repo: RepoState, modules: Module[], dic: Dictionary<number>) {
    let count = 0;
    let lastLightState = 0;
    modules.forEach(m => {
        const isCorner = repo.basemodules.find(b => b.id === m.base)?.isCorner;
        let isDouble = repo.basecabinets.find(b => b.id === m.cabinet)?.isDouble;
        const lightState = m.light ? 1 : 0;
        //const lightState = m.light ? m.cupboard === partCupboardDouble ? 2 : 1 : 0; // height of cupboard
        if (lastLightState !== lightState) {
            //console.log(count);
            // add previous tables if a change in material occurs
            if (lastLightState > 0) {
                altd(count, dic);
            }
            lastLightState = lightState;
            count = isCorner || isDouble ? 2 : 1;
        } else {
            count += isCorner || isDouble ? 2 : 1;
        }
    });
    if (lastLightState > 0) {
        // add unaccounted lights
        altd(count, dic);
    }
}

// add lights to dictionary (helper function)
function altd(count: number, dic: Dictionary<number>) {
    if (count <= 0) return;

    let tableCount = [0, 0, 0];
    switch (count % 3) {
        // Add only 3 length lights
        case 0:
            tableCount[2] = count / 3;
            break;
        // Add a 1 length light if count is 1, else add 2x2 length lights + 3 length lights for the rest
        case 1:
            if (count === 1) tableCount[0] = 1; else {
                tableCount[1] = 2;
                tableCount[2] = (count - 4) / 3;
            }
            break;
        // Add a 2 length light + 3 length lights for the rest
        case 2:
            tableCount[1] = 1;
            tableCount[2] = (count - 2) / 3;
            break;
    }
    for (var i = 0; i < 3; i++) {
        if (tableCount[i] > 0) dic[lightKits[i]] = dic[lightKits[i]] ? dic[lightKits[i]] + tableCount[i] : tableCount[i];
    }
}

function addTabletops(configuration: ConfigurationState, repo: RepoState, dic: Dictionary<number>) {
    let carts = repo.basecabinets.filter(c => c.isCart).map(c => c.id);
    atfa(configuration, configuration.left, repo, dic, carts);
    atfa(configuration, configuration.right, repo, dic, carts);
    if (configuration.corner && configuration.corner.tabletop) {
        // Add corner table
        atttd(1, repo, dic, configuration.corner.tabletop, true);
    }
}

// add tabletops from array (helper function)
function atfa(configuration: ConfigurationState, modules: Module[], repo: RepoState, dic: Dictionary<number>, carts: string[]) {
    let lastTableTop: Material | undefined = undefined;
    let smallestTables: number[] = [];
    let doubleEmptyCount = 0;
    let doubleEmptyCheck = false;
    let laminate = false;
    for (var i = 0; i < modules.length; i++) {
        let module = modules[i];
        let moduleData = repo.allProducts[module.cabinet ?? module.base];
        if (module.tabletop === "Laminate") {
            laminate = true;
        }
        if (module.tabletop !== lastTableTop) {
            if (smallestTables.length > 0) {
                atbs(smallestTables, repo, dic, lastTableTop, laminate);
                smallestTables = [];
            }
            lastTableTop = module.tabletop;
        }

        if (laminate) {
            if (module.tabletop === undefined) {
                // do nothing
            } else if ((module.cabinet === undefined && IsNeighborCabinetEmpty(repo, configuration, module.id, false) !== -1) || (module.cabinet === XLCart && module.isValidXLCart)) {
                if (module.cabinet === XLCart || smallestTables[smallestTables.length - 1] !== 4) {
                    doubleEmptyCount++;
                    doubleEmptyCheck = true;
                    smallestTables.push(4);
                    if (smallestTables.length > 1) {
                        smallestTables[smallestTables.length - 2] = smallestTables[smallestTables.length - 2] - 1;
                        if (smallestTables[smallestTables.length - 2] === 0) {
                            smallestTables.splice(smallestTables.length - 2, 1);
                        }
                    }
                }
            } else if (moduleData.isDouble === true) {
                if (doubleEmptyCheck === true) {
                    smallestTables.push(1);
                    doubleEmptyCheck = false;
                } else {
                    smallestTables.push(2);
                }
            } else if (moduleData.isCart === true || module.cabinet === undefined) {
                if (doubleEmptyCheck === true) {
                    smallestTables.pop();
                    smallestTables.push(2);
                    doubleEmptyCheck = false;
                } else {
                    if (smallestTables.length > 0) {
                        smallestTables[smallestTables.length - 1] = smallestTables[smallestTables.length - 1] - 1;
                        if (smallestTables[smallestTables.length - 1] === 0) {
                            smallestTables.pop();
                        }
                    }
                    smallestTables.push(3);
                }

                doubleEmptyCheck = true;
            } else {
                if (doubleEmptyCheck === true) {
                    doubleEmptyCheck = false;
                } else {
                    smallestTables.push(1);
                }
            }
        } else {

            laminate = false;
            if (module.tabletop === undefined) {
                // do nothing
            } else if (moduleData.isDouble === true && !doubleEmptyCheck) {
                smallestTables.push(2);
            } else if (moduleData.isCart === true || module.cabinet === undefined) {
                if (smallestTables.length > 0) {
                    smallestTables[smallestTables.length - 1] = smallestTables[smallestTables.length - 1] - 1;
                    if (smallestTables[smallestTables.length - 1] === 0) {
                        smallestTables.pop();
                    }

                    smallestTables.push(3);
                    doubleEmptyCheck = true;
                }
            } else {
                if (doubleEmptyCheck === true && !moduleData.isDouble) {
                    doubleEmptyCheck = false;
                } else {

                    smallestTables.push(1);
                }
            }
        }
    }
    atbs(smallestTables, repo, dic, lastTableTop, laminate);

    /*modules.forEach(m =>
    {
        let isDouble = repo.allProducts[m.cabinet ?? m.base].isDouble === true;
        if (lastTableTop !== m.tabletop)
        {
            // add previous tables if a change in material occurs
            atttd(count, repo, dic, lastTableTop, false);
            lastTableTop = m.tabletop;
            count = isDouble ? 2 : 1;
        } else if (m.cabinet && carts.findIndex(c => c === m.cabinet) >= 0)
        {
            // add previous tables before the table used for the cart
            atttd(count - 1, repo, dic, lastTableTop, false);
            // add the cart table of length 3
            atttd(3, repo, dic, lastTableTop, false);
            // set count to ignore the next table
            count = -1;
        } else
        {
            count += isDouble ? 2 : 1;
        }
    });
    // add unaccounted tables
    atttd(count, repo, dic, lastTableTop, false);*/
}

// add table by smallest
function atbs(smallestTables: number[], repo: RepoState, dic: Dictionary<number>, material: Material | undefined, laminate: boolean) {

    if (smallestTables.length <= 0 || material === undefined) return;
    let tables = repo.tabletop.filter(t => t.isCorner !== true && material === t.material).sort((t1, t2) => {
        return (t1.length as number) - (t2.length as number)
    }).map(t => t.id);
    if (tables.length === 0) {
        console.log("No tabletops to perform calculations");
    }
    let maxLength = tables.length; // Max table length, assuming table lengths of 1, 2 ..

    if (!laminate) {
        for (var i = 0; i < smallestTables.length - 1; i++) { // Make 1+1=2 and make 2's and 3's non spliceable
            if (smallestTables[i] === 1 && smallestTables[i + 1] === 1) {
                smallestTables.splice(i, 2, 2);
            } else if (smallestTables[i] > 1) {
                smallestTables[i] = -smallestTables[i];
            }
        }
        if (maxLength >= 3) { // Make 1 + 2 = 3
            for (let i = 0; i < smallestTables.length - 1; i++) {
                if (Math.abs(smallestTables[i]) + Math.abs(smallestTables[i + 1]) === 3) {
                    smallestTables.splice(i, 2, -3);
                }
            }
        }
        if (maxLength >= 3) { // Make 2 + 2 + 2 = 3 + 3
            for (let i = 0; i < smallestTables.length - 2; i++) {
                if (Math.abs(smallestTables[i]) === 2 && smallestTables[i + 1] === 2 && Math.abs(smallestTables[i + 2]) === 2) {
                    smallestTables.splice(i, 3, -3, -3);
                }
            }
        }
        if (maxLength >= 4) { // Make 2 + 2 = 4 or 1 + 3 = 4
            for (let i = 0; i < smallestTables.length - 1; i++) {
                if (Math.abs(smallestTables[i]) + Math.abs(smallestTables[i + 1]) === 4) {
                    smallestTables.splice(i, 2, -4);
                }
            }
        } else { // make 1 + 3 = 2 + 2
            for (let i = 0; i < smallestTables.length - 1; i++) {
                if (Math.abs(smallestTables[i]) === 1 && Math.abs(smallestTables[i + 1]) === -3) {
                    smallestTables.splice(i, 2, 2);
                    smallestTables.splice(i + 1, 2, 2);
                }
            }
        }
    } else {

        for (var i = 0; i < smallestTables.length - 1; i++) { // Make 1+1=2 and make 2's and 3's non spliceable
            if (smallestTables[i] === 1 && smallestTables[i + 1] === 1) {
                smallestTables.splice(i, 2, 2);
            } else if (smallestTables[i] > 1) {
                smallestTables[i] = -smallestTables[i];
            }
        }

        if (maxLength >= 3) { // Make 1 + 2 = 3
            for (let i = 0; i < smallestTables.length - 1; i++) {
                if (Math.abs(smallestTables[i]) + Math.abs(smallestTables[i + 1]) === 3) {
                    smallestTables.splice(i, 2, -3);
                }
            }
        }
        if (maxLength >= 3) { // Make 2 + 2 + 2 = 3 + 3
            for (let i = 0; i < smallestTables.length - 2; i++) {
                if (Math.abs(smallestTables[i]) === 2 && smallestTables[i + 1] === 2 && Math.abs(smallestTables[i + 2]) === 2) {
                    smallestTables.splice(i, 3, -3, -3);
                }
            }
        }
        if (maxLength >= 4) { // Make 2 + 2 = 4 or 1 + 3 = 4
            for (let i = 0; i < smallestTables.length - 1; i++) {
                if (Math.abs(smallestTables[i]) + Math.abs(smallestTables[i + 1]) === 4) {
                    smallestTables.splice(i, 2, -4);
                }
            }
        }
        if (maxLength >= 4 && smallestTables.length === 3) { // Make 2 + 3 + 3 = 4 + 4
            for (let i = 0; i < smallestTables.length - 1; i++) {
                if (Math.abs(smallestTables[i]) + Math.abs(smallestTables[i + 1]) + Math.abs(smallestTables[i + 2]) === 8) {
                    smallestTables.splice(i, 2, -4);
                    smallestTables.splice(i + 1, 1, -4);
                }
            }
        }
        if (maxLength >= 4 && smallestTables.length === 4) { // make 12 hardcoded
            let sum = 0;
            for (let i = 0; i < smallestTables.length; i++) {
                sum += Math.abs(smallestTables[i]);
            }
            if (sum === 12) {
                smallestTables = [4, 4, 4]
            }
        }
    }

    for (let i = 0; i < smallestTables.length; i++) {
        var type = tables[Math.abs(smallestTables[i]) - 1]; // Tabletop type from index (length - 1)
        dic[type] = dic[type] ? dic[type] + 1 : 1; // Add tabletop to dic
    }
}

// add tabletops to dictionary (helper function)
function atttd(count: number, repo: RepoState, dic: Dictionary<number>, material: Material | undefined, isCorner: boolean) {
    if (count <= 0 || material === undefined) return;
    if (isCorner) {
        const cornerTable = repo.tabletop.find(t => t.isCorner === true && material === t.material)?.id;
        if (cornerTable) dic[cornerTable] = 1;
    } else {
        let tables = repo.tabletop.filter(t => t.isCorner !== true && material === t.material).sort((t1, t2) => {
            return (t1.length as number) - (t2.length as number)
        }).map(t => t.id);
        if (tables.length === 0) {
            console.log("No tabletops to perform calculations");
        }
        let maxLength = tables.length; // Max table length, assuming table lengths of 1, 2 ..
        let amount = Math.ceil(count / maxLength); // Number of tabletops to order
        let remove = (maxLength * amount - count) % maxLength; // Total length to remove from setup of tabletops of max length
        let removeFromAll = Math.floor(remove / amount); // Length to remove from all tabletops
        let removeFromFirst = remove % amount; // Amount of tabletops to remove an extra length of 1

        for (var i = 0; i < amount; i++) {
            let tableLength = maxLength - removeFromAll - (i < removeFromFirst ? 1 : 0); // Tabletop length
            var type = tables[tableLength - 1]; // Tabletop type from index (length - 1)
            dic[type] = dic[type] ? dic[type] + 1 : 1; // Add tabletop to dic
        }
    }
}

export const getSize = (configuration: ConfigurationState, repo: RepoState) => {
    const size: Size = {
        width: 0,
        height: 0,
        depth: 0
    };

    if (configuration.corner) {
        let part = getProduct(repo, configuration.corner.base)
        if (part !== undefined && part.size) {
            const length = part.size.width;
            size.width = length;
            size.depth = length;
            size.height = part.size.height;
        }
    }

    configuration.left.reduce<Size>((size, p) => {
        let part = getProduct(repo, p.base);
        let cabinet = getProduct(repo, p.cabinet);
        let cabinetHeight = 0;
        repo.basecabinets.reduce(x => {
            cabinetHeight = Math.max(cabinetHeight, x.size?.height ?? 0)
            return x;
        });
        let lifter = getProduct(repo, p.lifter);
        let toolboard = getProduct(repo, p.panel);
        let calculatedHeight = 0;
        if (toolboard !== undefined) {
            calculatedHeight = part?.size?.height ?? 0;
        } else if (part?.isBaseModule) {
            calculatedHeight = cabinetHeight + (p.isLifted ? (lifter?.size?.height ?? 0) : 0) + 38 //38 is a magic number defining the height of the tabletop;
        } else {
            calculatedHeight = part?.size?.height ?? 0;
        }

        if (!part || !part.size) return size;
        size.depth = Math.max(part.size.depth, size.depth);
        size.height = Math.max(calculatedHeight, size.height);
        size.width += (cabinet && cabinet.size && cabinet.size.width > part.size.width) ? cabinet.size.width : part.size.width;
        return size;
    }, size);

    configuration.right.reduce<Size>((size, p) => {
        let part = getProduct(repo, p.base);
        let cabinet = getProduct(repo, p.cabinet);
        let cabinetHeight = 0;
        repo.basecabinets.reduce(x => {
            cabinetHeight = Math.max(cabinetHeight, x.size?.height ?? 0)
            return x;
        });
        let lifter = getProduct(repo, p.lifter);
        let toolboard = getProduct(repo, p.panel);
        let calculatedHeight = 0;
        if (toolboard !== undefined) {
            calculatedHeight = part?.size?.height ?? 0;
        } else if (part?.isBaseModule) {
            calculatedHeight = cabinetHeight + (p.isLifted ? (lifter?.size?.height ?? 0) : 0) + 38 //38 is a magic number defining the height of the tabletop;
        } else {
            calculatedHeight = part?.size?.height ?? 0;
        }

        if (!part || !part.size) return size;
        size.width = Math.max(part.size.depth, size.width);
        size.height = Math.max(calculatedHeight, size.height);
        size.depth += (cabinet && cabinet.size && cabinet.size.width > part.size.width) ? cabinet.size.width : part.size.width;
        return size;
    }, size);
    return size;
}

export const getCustomSize = (configuration: ConfigurationState, repo: RepoState) => {
    const size: Size = {
        width: 0,
        width2: 0,
        height: 0,
        depth: 0
    };
    /* if corner is enabled, client wants depth of cabinets not depth of used area*/
    let maxDepth = 0;
    let hasCabinet = false;
    if (configuration.corner) {
        let part = getProduct(repo, configuration.corner.base)

        if (part !== undefined && part.size) {
            const length = part.size.width;
            size.width = length;
            size.depth = length;
            size.height = part.size.height;
        }
    }

    configuration.left.reduce<Size>((size, p) => {
        let part = getProduct(repo, p.base);
        let cabinet = getProduct(repo, p.cabinet);
        let cabinetHeight = 0;
        repo.basecabinets.reduce(x => {
            cabinetHeight = Math.max(cabinetHeight, x.size?.height ?? 0)
            return x;
        });
        let lifter = getProduct(repo, p.lifter);
        let toolboard = getProduct(repo, p.panel);
        let calculatedHeight = 0;

        if (toolboard !== undefined) {
            calculatedHeight = part?.size?.height ?? 0;
        } else if (part?.isBaseModule) {
            calculatedHeight = cabinetHeight + (p.isLifted ? (lifter?.size?.height ?? 0) : 0) + 38 //38 is a magic number defining the height of the tabletop;
        } else {
            calculatedHeight = part?.size?.height ?? 0;
        }

        if (!part || !part.size) return size;
        size.depth = Math.max(part.size.depth, size.depth);
        size.height = Math.max(calculatedHeight, size.height);
        size.width += (cabinet && cabinet.size && cabinet.size.width > part.size.width) ? cabinet.size.width : part.size.width;
        if (!p.tabletop) hasCabinet = true;
        if (part.size.depth > maxDepth) {
            maxDepth = part.size.depth;
        }
        return size;
    }, size);

    configuration.right.reduce<Size>((size, p) => {
        let part = getProduct(repo, p.base);
        let cabinet = getProduct(repo, p.cabinet);
        let cabinetHeight = 0;
        repo.basecabinets.reduce(x => {
            cabinetHeight = Math.max(cabinetHeight, x.size?.height ?? 0)
            return x;
        });
        let lifter = getProduct(repo, p.lifter);
        let toolboard = getProduct(repo, p.panel);
        let calculatedHeight = 0;
        if (toolboard !== undefined) {
            calculatedHeight = part?.size?.height ?? 0;
        } else if (part?.isBaseModule) {
            calculatedHeight = cabinetHeight + (p.isLifted ? (lifter?.size?.height ?? 0) : 0) + 38 //38 is a magic number defining the height of the tabletop;
        } else {
            calculatedHeight = part?.size?.height ?? 0;
        }

        if (!part || !part.size) return size;
        size.width = Math.max(part.size.depth, size.width);
        size.height = Math.max(calculatedHeight, size.height);
        size.depth += (cabinet && cabinet.size && cabinet.size.width > part.size.width) ? cabinet.size.width : part.size.width;
        if (part.size.depth > maxDepth) {
            maxDepth = part.size.depth;
        }
        if (!p.tabletop) hasCabinet = true;

        return size;
    }, size);

    /* if no cabinets, cupboareds or panels are found, show height for base cabinet */
    if (!configuration.defaultCupboards && !configuration.defaultPanels && !hasCabinet && !configuration.defaultLifter) {
        /* TODO fetch from base cabinet from repo */
        size.height = 948; //base cabinet height + tabletop height?
    }

    /* if no cabinets, cupboareds or panels are found, show height for base cabinet */
    let hasNoPanelsOrCupboards = configuration.left.every(x => x.cupboard === undefined && x.panel === undefined) && configuration.right.every(x => x.cupboard === undefined || x.panel === undefined);
    if (hasNoPanelsOrCupboards) {
        /* TODO fetch from base cabinet from repo */
        size.depth = size.depth - 30; //magic number - replace with real depth ?? 
    }

    /* client wanted width to be width x width when corner is added */
    if (configuration.corner) {
        return {...size, width2: size.depth, depth: maxDepth};
    }


    return size;
}
